.sidebar::before {
    content: "";
    background-image: url('./assets/sidebar.webp');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.50;
}
